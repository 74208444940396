// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clases-js": () => import("./../../src/pages/clases.js" /* webpackChunkName: "component---src-pages-clases-js" */),
  "component---src-pages-contacto-js": () => import("./../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructores-js": () => import("./../../src/pages/instructores.js" /* webpackChunkName: "component---src-pages-instructores-js" */)
}

